<template>

	<div>

		<a-row type="flex" :gutter="24">
			<a-col :span="12" class="mb-24">
				<a-button type="primary" @click="$router.push('/places-to-stay-answers/create')">New Answer</a-button>
			</a-col>
		</a-row>

		<a-card :bordered="false" class="header-solid mb-24" :bodyStyle="{ padding: 0, paddingTop: '16px' }">
			<div v-if="isDeleteError" class="alert alert-warning" role="alert">
              <p class="font-bold">Oops</p>
              <p>You can not delete places to stay answers that are linked to other infos!</p>
            </div>
			<!-- Table search -->
			<div class="mx-25">
				<a-row type="flex" :gutter="24">
					<a-col :span="24" class="text-right">
						<a-button v-if="selectedRowKeys.length > 0" type="danger" @click="deleteSelectedRows()"
							style="margin-right: 10px;">
							<a-icon type="delete" />
						</a-button>
						<a-input-search placeholder="input search text" style="max-width: 200px;" v-model="query"
							@input="debouncedSearch" />
					</a-col>
				</a-row>
			</div>
			<!-- / Table search -->

			<a-table class="mt-20" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
				:columns="columns" :data-source="placesToStay" rowKey="id"
				:pagination="{ pageSize: pageSize, current: currentPage, total: totalCount, onChange: handlePageChange }"
				:loading="isNotLoading">
				<!-- Name Column -->
				<template slot="name" slot-scope="name">{{ name }}</template>

				<template slot="action" slot-scope="action">
					<a-button type="primary" @click="$router.push(`/places-to-stay-answers/edit/${action}`)">
						<a-icon type="edit" />
					</a-button>
				</template>
			</a-table>
			<!-- / Orders table -->

		</a-card>
		<!-- / Orders List card -->

	</div>

</template>

<script>
import placesToStayAPI from '../../api/placesToStay';
import debounce from 'lodash/debounce';
// Table columns
const columns = [
	{
		title: 'TITLE',
		dataIndex: 'title',
		sorter: (a, b) => a.title > b.title ? 1 : -1,
		sortDirections: ['descend', 'ascend'],
		scopedSlots: { customRender: 'title' },
	},
	{
		title: 'STATUS',
		dataIndex: 'is_active',
		sorter: (a, b) => a.is_active === b.is_active ? 0 : a.is_active ? -1 : 1,
		sortDirections: ['descend', 'ascend'],
		scopedSlots: { customRender: 'is_active' },
	},
	{
		title: 'ACTION',
		dataIndex: 'id',
		scopedSlots: { customRender: 'action' },
	},
];

export default {
	data() {
		return {

			// Table columns
			columns,

			// Table Authors
			placesToStay: [],

			// Table page size
			pageSize: 10,

			// Table page skip
			currentPage: 1,

			totalCount: 0,

			// Table search query
			query: '',

			// Table's selected rows
			selectedRowKeys: [],

			isNotLoading: false,
			isDeleteError: false,

			debouncedSearch: () => { },
		}
	},
	computed: {

		// CSV data array
		// csvData() {
		// 	return this.placesToStay.map(item => ({
		// 		"Id": "%23" + item.key,
		// 		"Date": item.date,
		// 		"Status": item.status,
		// 		"Customer": item.customer.name,
		// 		"Product": item.product,
		// 		"Revenue": "$" + item.revenue,
		// 	}));
		// }

	},
	methods: {
		async deleteSelectedRows() {
			await placesToStayAPI.deletePlacesToStay({
				"selected_row_keys": this.selectedRowKeys,
			}).then(() => {
				if ((this.selectedRowKeys.length === this.placesToStay.length || this.placesToStay.count === this.selectedRowKeys.length) && this.currentPage > 1) {
					this.handlePageChange(this.currentPage - 1);
				} else {
					this.handlePageChange(this.currentPage);
				}
				this.selectedRowKeys = [];
			})
		},
		async handlePageChange(page) {
			this.currentPage = page;
			const data = await this.getPlacesToStay({
				params: {
					"limit": this.pageSize,
					"skip": (this.currentPage - 1) * this.pageSize,
				}
			})
			this.placesToStay = data.placesToStay;
			this.totalCount = data.count;
			this.isNotLoading = false;
		},

		async getPlacesToStayAnswers(filter) {
			this.isNotLoading = true;
			return placesToStayAPI.getPlacesToStayAnswers(filter);
		},

		async onSearchChange() {
			if (this.query.length >= 3) {
				const data = await this.getPlacesToStayAnswers({
					params: {
						"limit": this.pageSize,
						"offset": 0,
						"query": this.query,
					}
				})
				this.placesToStay = data.placesToStay;
				if (Object.keys(data).length !== 0) {
					this.totalCount = data.count;
				} else {
					this.totalCount = 0;
				}
				this.isNotLoading = false;
			} else {
				const originalData = await this.getPlacesToStay({
					params: {
						"limit": this.pageSize,
						"skip": 0,
					}
				});
				this.placesToStay = originalData.placesToStay;
				if (Object.keys(originalData).length !== 0) {
					this.totalCount = originalData.count;
				} else {
					this.totalCount = 0;
				}
				this.isNotLoading = false;
			}
		},

		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys;
			console.log(this.selectedRowKeys);
		},

		// csvExport(arrData) {
		// 	let csvContent = "data:text/csv;charset=utf-8,";
		// 	csvContent += [
		// 		Object.keys(arrData[0]).join("|"),
		// 		...arrData.map(item => Object.values(item).join("|"))
		// 	]
		// 		.join("\n")
		// 		.replace(/(^\[)|(\]$)/gm, "");

		// 	const data = encodeURI(csvContent);
		// 	const link = document.createElement("a");
		// 	link.setAttribute("href", data);
		// 	link.setAttribute("download", "muse-dashboard-csv.csv");
		// 	link.click();
		// }
	},
	async mounted() {
		this.debouncedSearch = debounce(this.onSearchChange, 300);
		const data = await this.getPlacesToStayAnswers({
			params: {
				"limit": this.pageSize,
				"offset": (this.currentPage - 1) * this.pageSize,
			}
		})
		if (Object.keys(data).length !== 0) {
			this.placesToStay = data.answers;
			this.totalCount = data.answers.length > 0 ? data.count : 0;
		}
		this.isNotLoading = false;
	},
}

</script>

<style lang="scss" scoped>
.product-img {
  border-radius: 12px;
}

.alert {
  font-weight: bold;
  background-color: #FEEBC8;
  border-left: 4px solid #F59E0B;
  color: #F59E0B;
  padding: 0.5rem;
  margin: 10px;
}

.alert p.font-bold {
  font-weight: bold;
}
</style>