import client from "./client";

const getPlacesToStay = (id) => {
  return client
    .get(`/places-to-stay/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => error.response);
};

const getPlacesToStayAnswers = (filter) => {
  return client
    .get(`/places-to-stay-answers`, filter)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => error.response);
};

const createPlacesToStay = (data) => {
  return client
    .post("/places-to-stay", data)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

const updatePlacesToStay = (id, data) => {
  return client
    .put(`/places-to-stay/${id}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

const deletePlacesToStays = (data) => {
  return client
    .put(`/places-to-stay`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

const placesToStayAPI = {
  getPlacesToStay,
  createPlacesToStay,
  updatePlacesToStay,
  deletePlacesToStays,
  getPlacesToStayAnswers,
};

export default placesToStayAPI;
